import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Model from "../components/Model";
import { BsEmojiSmile, BsFillEmojiSmileFill } from "react-icons/bs";
import { fetchMessages, sendMessage } from "../apis/messages";
import { useEffect } from "react";
import MessageHistory from "../components/MessageHistory";
import io from "socket.io-client";
import "./home.css";
import {
  fetchChats,
  setActiveChat,
  setNotifications,
} from "../redux/chatsSlice";
import Loading from "../components/ui/Loading";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { toast } from "react-toastify";
import { getChatName } from "../utils/logics";
import Typing from "../components/ui/Typing";
import { validUser } from "../apis/auth";
import axios from "axios";
import { FaImages } from "react-icons/fa6";
import { IoDocumentText } from "react-icons/io5";
import { FaCircleChevronDown } from "react-icons/fa6";
import { IoMdSend } from "react-icons/io";
import { GrAttachment } from "react-icons/gr";
import { messaging, getToken, onMessage } from "../services/firebase";
import LexicalRichTextEditor from "../components/LexicalRichTextEditor";
import { $getRoot, $getSelection } from "lexical";

const ENDPOINT = process.env.REACT_APP_SERVER_URL;
let socket, selectedChatCompare;

const textareaStyle = {
  width: "100%",
  height: "35px",
  // paddingLeft: '12px',
  padding: "5px 12px",
  backgroundColor: "#fff",
  resize: "none",
};

function Chat(props) {
  const [imagePreview, setImagePreview] = useState(null);
  const [docPreview, setDocPreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { activeChat, notifications } = useSelector((state) => state.chats);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [token, setToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const chatContainerRef = useRef(null);
  const scrollRef = useRef(null); // Create a ref to ScrollableFeed
  const [showLoadMessage, setShowLoadMessage] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const activeUser = useSelector((state) => state.activeUser);
  const [topPosition, setTopPosition] = useState("-10px");
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [seenMessages, setSeenMessages] = useState([]);

  // console.log(activeUser.id);

  const API = (token) =>
    axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL,
      headers: { Authorization: token },
    });

  const keyDownFunction = async (e) => {
    const isEnterKeyWithoutShift = e.key === "Enter" && !e.shiftKey;
    const isClickWithoutShift = e.type === "click";

    if ((isEnterKeyWithoutShift || isClickWithoutShift) && message) {
      e.preventDefault();
      socket.emit("stop typing", activeChat._id);
      try {
        const data = await sendMessage({ chatId: activeChat._id, message });
        socket.emit("new message", data, activeChat._id);
        // console.log(data);
        setMessages((prevMessages) => [...prevMessages, data]);
        dispatch(fetchChats());
        setMessage("");
        const textarea = document.querySelector("textarea[name='message']");
        textarea.style.height = "auto";
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  useEffect(() => {
    socket = io(ENDPOINT);
    socket.on("typing", () => setIsTyping(true));
    socket.on("stop typing", () => setIsTyping(false));

    socket.on("onlineUsers", (users) => {
      // console.log(users);
      setOnlineUsers(users);
    });
  }, []);

  const handleButtonClick = () => {
    setMenuOpen(!menuOpen);
    setImagePreview(null);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    // console.log("selected file", file);
    setSelectedImage(event.target.files[0]);
    if (file) {
      const reader = new FileReader();
      setShowPopup(true);
      setMenuOpen(false);
      setDocPreview(false);
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    // console.log("selected file", file.type);
    setSelectedImage(event.target.files[0]);
    if (file) {
      const reader = new FileReader();
      setShowPopup(true);
      setMenuOpen(false);
      reader.onload = () => {
        setDocPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSendImage = async () => {
    // console.log(selectedImage);
    try {
      const formData = new FormData();
      formData.append("image", selectedImage);
      formData.append("chatId", activeChat._id);
      const token = localStorage.getItem("userToken");

      const data = await API(token).post(`/api/message/sendimage`, formData);
      const chatId = data.data;
      setShowPopup(false);
      setMenuOpen(false);

      socket.emit("new message", chatId, activeChat._id);
      setMessages([...messages, chatId]);
      dispatch(fetchChats());
      return data;
    } catch (error) {
      toast.error("Something Went Wrong. Try Again!");
    }
  };

  const handleSendDoc = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedImage);
      formData.append("chatId", activeChat._id);
      const token = localStorage.getItem("userToken");

      const data = await API(token).post(`/api/message/send-doc`, formData);
      const chatId = data.data;
      // console.log(chatId);
      setShowPopup(false);
      setMenuOpen(false);
      socket.emit("new message", chatId, activeChat._id);
      setMessages([...messages, chatId]);
      dispatch(fetchChats());

      return data;
    } catch (error) {
      toast.error("Something Went Wrong. Try Again!");
    }
  };

  useEffect(() => {
    socket.emit("setup", activeUser);
    socket.on("connected", () => {
      setSocketConnected(true);
    });
  }, [messages, activeUser]);

  useEffect(() => {
    const fetchMessagesFunc = async () => {
      if (activeChat) {
        setLoading(true);
        const data = await fetchMessages(activeChat._id, currentPage);
        // console.log("currentPage", currentPage);

        setMessages(data);
        scrollToBottom();

        socket.emit("join room", activeChat._id);
        // socket.emit("markAsSeen", activeChat , messages)
        // if (data.length > 0) {
        //   const messageIds = data.map((msg) => msg._id); // Get message IDs
        //   socket.emit("markAsSeen", activeChat._id, messageIds); // Mark all messages in this chat as seen
        // }
        setLoading(false);
      }
      return;
    };
    fetchMessagesFunc();
    selectedChatCompare = activeChat;
  }, [activeChat]);

  // useEffect(() => {
  //   // When the chat window is opened, mark messages as seen
  //   const unseenMessageIds = messages
  //     .filter((message) => !message.seenStatus)
  //     .map((message) => message._id);
  //   console.log(unseenMessageIds);
    
  //   if (unseenMessageIds.length > 0) {
  //     socket.emit("markAsSeen", activeChat._id, unseenMessageIds);
  //   }
  // }, [activeChat._id, messages]);

  // useEffect(() => {
  //   // Listen for the "message seen" event and update the messages state
  //   socket.on("message seen", ({ messageIds, chatId: receivedChatId }) => {

  //     if (receivedChatId === activeChat._id) {
  //       setMessages((prevMessages) =>
  //         prevMessages.map((message) =>
  //           messageIds.includes(message._id)
  //             ? { ...message, seenStatus: true }
  //             : message
  //         )
  //       );
  //     }
  //   });

  // }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeChat]);

  const fetchMoreMessages = async () => {
    try {
      const data = await fetchMessages(activeChat._id, currentPage + 1);
      setMessages((prevMessages) => [...data, ...prevMessages]);
      setCurrentPage((prevPage) => prevPage + 1);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching more messages:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const requestPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        getToken(messaging, {
          vapidKey: process.env.FIREBASE_VAPID_KEY,
        })
          .then((currentToken) => {
            if (currentToken) {
              setToken(currentToken);
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      } else {
        console.log("Unable to get permission to notify.");
      }
    };
    requestPermission();
  }, []);

  useEffect(() => {
    socket.on("message recieved", (newMessageRecieved) => {
      // console.log("token", token);

      if (
        (!selectedChatCompare || selectedChatCompare._id) !==
        newMessageRecieved.chatId._id
      ) {
        if (!notifications.includes(newMessageRecieved)) {
          dispatch(setNotifications([newMessageRecieved, ...notifications]));
        }
      } else {
        setMessages((prevMessages) => [...prevMessages, newMessageRecieved]);
      }
      // dispatch(fetchChats());
    });
  }, [notifications]);

  useEffect(() => {
    socket.on("message recieved", (newMessageRecieved) => {
      // console.log("newMessageRecieved",newMessageRecieved);

      if (token) {
        new Notification(newMessageRecieved.sender.name, {
          body: "You have received new message",
          icon: "https://www.srfc.org.in/upload/logo.png",
        });
      } else {
        console.log("no registration token");
      }
    });
  }, [token]);

  useEffect(() => {
    const isValid = async () => {
      const data = await validUser();
      if (!data?.user) {
        window.location.href = "/login";
      }
    };
    isValid();
  }, []);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      const handleScroll = async () => {
        if (container.scrollTop === 0) {
          // await fetchMoreMessages();
          setShowLoadMessage(true);
          container.scrollTop = 10;
        }
      };
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [chatContainerRef, fetchMoreMessages]);

  const handleLoadMoreClick = (event) => {
    event.preventDefault();
    setLoadMore(true);
    setTimeout(() => {
      fetchMoreMessages();
      setShowLoadMessage(false);
      scrollToBottom();
      setLoadMore(false);
    }, 1000);
  };

  const handleEditorChange = (editorState) => {
    editorState.read(() => {
      const root = $getRoot();
      const selection = $getSelection();

      // Process the editor state to get the current message as a string
      const messageText = root.getTextContent();
      setMessage(messageText);
      console.log("messageText", messageText);
    });
  };

  useEffect(() => {
    socket.on("onlineUsers", (users) => {
      setOnlineUsers(users);
    });
  }, [onlineUsers]);

  if (loading) {
    return (
      <div className={props.className}>
        <Loading />
      </div>
    );
  }

  const otherUserId = activeChat?.users?.find(
    (userId) => userId?._id !== activeUser.id
  );

  const isOnline = onlineUsers.some((user) => user.userId === otherUserId?._id);

  return (
    <>
      {activeChat ? (
        <div className={props.className}>
          <div className="flex justify-between items-center px-5 bg-[#ffff] w-[100%]">
            <div className="flex items-center gap-x-[10px]">
              <div className="flex flex-col items-start justify-center">
                <h5 className="text-[17px] text-[#2b2e33] font-bold tracking-wide">
                  {getChatName(activeChat, activeUser)}
                </h5>
                {isOnline && (
                  <p className="text-[13px] text-[#aabac8] -mt-1">online</p>
                )}
              </div>
            </div>
            <div>
              <Model />
            </div>
          </div>
          <div
            className="scrollbar-hide w-[100%] h-[70vh] md:h-[85vh] lg:h-[85vh] flex flex-col overflow-y-scroll p-4"
            ref={chatContainerRef}
          >
            {showLoadMessage && !loadMore && (
              <button
                className="text-gray-700 px-3 rounded-full flex items-center justify-center hover:text-gray-900 transition-colors duration-300"
                onClick={handleLoadMoreClick}
              >
                <span className="flex items-center space-x-2 ">
                  <span className="hidden md:inline text-gray-500 ">
                    click here to Load More
                  </span>
                </span>
              </button>
            )}

            {loadMore && (
              <div class="text-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}

            <MessageHistory typing={isTyping} messages={messages} />

            <div className="ml-7 -mb-10">
              {isTyping ? <Typing width="100" height="100" /> : ""}
            </div>
          </div>
          <div className="absolute left-[0%] bottom-[0%] w-[100%]">
            {/* {
                  showPicker && <Picker data={data} onEmojiSelect={(e) => setMessage(message + e.native)} />
                } */}
            <div
              className=" px-6 py-3 w-[360px] sm:w-[100%] md:w-[350px] h-[63px] lg:w-[100%]  flex items-center"
              style={{ backgroundColor: "#f0f2f5" }}
            >
              <div
                className="cursor-pointer"
                onClick={() => setShowPicker(!showPicker)}
              >
                <div style={{ padding: "0px", marginTop: "-10px" }}>
                  <button
                    onClick={handleButtonClick}
                    style={{
                      padding: "20px 20px 10px 0px",
                      cursor: "pointer",
                      outline: "none",
                    }}
                  >
                    <GrAttachment size={24} />
                  </button>
                  {menuOpen && (
                    <div
                      style={{
                        position: "absolute",
                        top: "-90px",
                        left: "0px",
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "10px",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                        zIndex: "999",
                        width: "150px",
                      }}
                    >
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="image-file-input"
                        type="file"
                        onChange={handleImageSelect}
                      />
                      <label
                        className="text-md flex justify-between items-center"
                        htmlFor="image-file-input"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <FaImages className=" h-5 w-5 text-blue-600" />
                        <p>Image</p>
                      </label>
                      <input
                        accept="pdf,csv,excel"
                        style={{ display: "none" }}
                        id="pdf-file-input"
                        type="file"
                        onChange={handleFileSelect}
                      />
                      <label
                        className="text-md flex justify-between items-center"
                        htmlFor="pdf-file-input"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <IoDocumentText className=" h-6 w-5 text-indigo-500" />
                        <p>Document</p>
                      </label>
                    </div>
                  )}
                  <div>
                    {showPopup && (
                      <div
                        className="popup"
                        style={{
                          position: "absolute",
                          top: "-470px",
                          left: "20px",
                          backgroundColor: "#fff",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          padding: "10px",
                          boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          width: "96%",
                          zIndex: "999999",
                        }}
                      >
                        <div class="flex justify-between items-start">
                          <div class="cursor-pointer">
                            <p>Preview</p>
                            <br></br>
                          </div>
                          <div>
                            <button
                              className="close-btn"
                              onClick={() => setShowPopup(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>

                        {imagePreview && (
                          <div
                            class="flex justify-center"
                            style={{ marginBottom: "10px" }}
                          >
                            <img
                              src={imagePreview}
                              alt="Selected"
                              style={{ maxWidth: "100%", maxHeight: "400px" }}
                            />
                          </div>
                        )}
                        {imagePreview && (
                          <div class="flex justify-center items-start">
                            <div class="cursor-pointer">
                              <button
                                onClick={handleSendImage}
                                style={{
                                  padding: "8px 16px",
                                  borderRadius: "20px",
                                  border: "none",
                                  backgroundColor: "#268d61",
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                              >
                                Send Image
                              </button>
                            </div>
                          </div>
                        )}
                        {docPreview && (
                          <div
                            class="flex justify-center"
                            style={{ marginBottom: "10px" }}
                          >
                            <img
                              src="https://cdn-icons-png.flaticon.com/512/5968/5968517.png"
                              alt="Document"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px",
                                marginBottom: "20px",
                              }}
                            />
                          </div>
                        )}
                        {docPreview && (
                          <div class="flex justify-center items-start">
                            <div class="cursor-pointer">
                              <button
                                onClick={handleSendDoc}
                                style={{
                                  padding: "8px 16px",
                                  borderRadius: "20px",
                                  border: "none",
                                  backgroundColor: "#268d61",
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                              >
                                Send Document
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <form
                className="flex-1"
                onKeyDown={(e) => keyDownFunction(e)}
                onSubmit={(e) => e.preventDefault()}
              >
                <textarea
                  className="focus:outline-0 p-2 rounded-md"
                  style={{
                    height: "auto",
                    padding: "5px 12px",
                    backgroundColor: "#fff",
                    resize: "none",
                    maxHeight: "150px",
                    overflowY: "auto",
                    position: "fixed",
                    bottom: "15px",
                    width: "54%",
                  }}
                  onChange={(e) => {
                    const textarea = e.target;
                    setMessage(textarea.value);

                    textarea.style.height = "auto";
                    textarea.style.height = textarea.scrollHeight + "px";

                    if (!socketConnected) return;
                    if (!typing) {
                      setTyping(true);
                      socket.emit("typing", activeChat._id);
                    }
                    let lastTime = new Date().getTime();
                    const time = 2000;
                    setTimeout(() => {
                      const timeNow = new Date().getTime();
                      const timeDiff = timeNow - lastTime;
                      if (timeDiff >= time && typing) {
                        socket.emit("stop typing", activeChat._id);
                        setTyping(false);
                      }
                    }, time);
                  }}
                  rows={1}
                  name="message"
                  placeholder="Enter message"
                  value={message}
                />
              </form>
              <button
                onClick={(e) => keyDownFunction(e)}
                className="text-[14px] py-[3px] text-[#686868] font-medium rounded-[7px] -mt-1 ml-3"
              >
                <IoMdSend size={27} />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={props.className}>
          <div className="relative">
            <div className="absolute top-[40vh] left-[44%] flex flex-col items-center justify-center gap-y-3">
              <img
                className="w-[50px] h-[50px] rounded-[25px]"
                alt="User profile"
                src={activeUser.profilePic}
              />
              <h3 className="text-[#111b21] text-[20px] font-medium tracking-wider">
                Welcome
                <span className="text-[#166e48] text-[19px] font-bold">
                  {activeUser.name}
                </span>
              </h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Chat;
