import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchUsers, validUser } from "../apis/auth";
import { setActiveUser } from "../redux/activeUserSlice";
import { RiNotificationBadgeFill } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { BiNotification } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { setShowNotifications, setShowProfile } from "../redux/profileSlice";
import Chat from "./Chat";
import Profile from "../components/Profile";
import { acessCreate } from "../apis/chat.js";
import "./home.css";
import { fetchChats, setNotifications } from "../redux/chatsSlice";
import { getSender } from "../utils/logics";
import { setActiveChat } from "../redux/chatsSlice";
import Group from "../components/Group";
import Contacts from "../components/Contacts";
import { Effect } from "react-notification-badge";
import NotificationBadge from "react-notification-badge";
import Search from "../components/group/Search";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,

  pb: 1,
  borderRadius: "5px",
};

function Home() {
  const dispatch = useDispatch();
  const { showProfile, showNotifications } = useSelector(
    (state) => state.profile
  );
  const { notifications } = useSelector((state) => state.chats);
  const { activeUser } = useSelector((state) => state);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [showUpdatePass, setShowUpdatePass] = useState(false);
  const handleOpen = () => setOpen(true);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showGroup, setShowGroup] = useState(false);
  const handleGroupOpen = () => setShowGroup(true);
  const handleGroupClose = () => setShowGroup(false);
  const handleClose = () => {
    setOpen(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const handleSearch = async (e) => {
    setSearch(e.target.value);
  };
  const handleClick = async (e) => {
    await acessCreate({ userId: e._id });
    dispatch(fetchChats());
    setSearch("");
  };

  useEffect(() => {
    const searchChange = async () => {
      setIsLoading(true);
      const { data } = await searchUsers(search);
      setSearchResults(data);
      setIsLoading(false);
    };
    searchChange();
  }, [search]);
  useEffect(() => {
    const isValid = async () => {
      const data = await validUser();
      const user = {
        id: data?.user?._id,
        email: data?.user?.email,
        profilePic: data?.user?.profilePic,
        bio: data?.user?.bio,
        name: data?.user?.name,
      };
      dispatch(setActiveUser(user));
    };
    isValid();
  }, [dispatch, activeUser]);

  const handleNotificationClick = () => {
    dispatch(setNotifications([]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert("New password and confirm new password do not match!");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/update-password/${activeUser.id}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ currentPassword, newPassword }),
        }
      );

      const data = await response.json();
      if (data.error) {
        toast.warning("Wrong Current Password!");
      } else {
        toast.success("Password Update Succesfully!");
        handleClose();
      }
    } catch (error) {
      toast.error("Error Updating Password!");
    }
  };
  return (
    <>
      <div className="bg-[#282C35!] scrollbar-hide z-10 h-[100vh]  lg:w-[90%] lg:mx-auto overflow-y-hidden shadow-2xl">
        <div className="flex">
          {!showProfile && !showUpdatePass ? (
            <div className="md:flex md:flex-col min-w-[360px] h-[100vh] md:h-[98.6vh] bg-[#ffff] relative">
              <div className="h-[61px] px-4">
                <div className="flex">
                  <a
                    className="flex items-center relative -top-4 h-[90px]"
                    href="/"
                  >
                    <h3 className="text-[18px] text-[#1f2228] font-body font-bold tracking-wider">
                      SRFC CHAT{" "}
                    </h3>
                  </a>
                </div>
                <div className="absolute top-4 right-5 flex items-center gap-x-3">
                  <button
                    onClick={() => dispatch(setShowProfile(true))}
                    className="flex items-center gap-x-1 relative"
                  >
                    <img
                      className="w-[28px] h-[28px] rounded-[25px]"
                      src={activeUser?.profilePic}
                      alt=""
                    />
                    {/* <IoIosArrowDown
                        style={{
                          color: "#616c76",
                          height: "14px",
                          width: "14px",
                        }}
                      /> */}
                  </button>
                  <button
                    onClick={() =>
                      dispatch(setShowNotifications(!showNotifications))
                    }
                  >
                    <NotificationBadge
                      count={notifications.length}
                      effect={Effect.SCALE}
                      style={{
                        width: "15px",
                        height: "15px",
                        fontSize: "9px",
                        padding: "4px 2px 2px 2px",
                      }}
                    />
                    {showNotifications ? (
                      <RiNotificationBadgeFill
                        style={{
                          width: "25px",
                          height: "25px",
                          color: "#319268",
                        }}
                      />
                    ) : (
                      <BiNotification
                        style={{
                          color: "#319268",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    )}
                  </button>
                  <div
                    className={`${
                      showNotifications
                        ? "overflow-y-scroll scrollbar-hide tracking-wide absolute top-10 -left-32 z-10 w-[240px] bg-[#fafafa] px-4 py-2 shadow-2xl"
                        : "hidden"
                    }`}
                  >
                    <div className="text-[13px]">
                      {!notifications.length && "No new messages"}
                    </div>
                    {notifications.map((e, index) => {
                      return (
                        <div
                          onClick={() => {
                            dispatch(setActiveChat(e.chatId));
                            dispatch(
                              setNotifications(
                                notifications.filter((data) => data !== e)
                              )
                            );
                          }}
                          key={index}
                          className="text-[12.5px] text-black px-2 cursor-pointer"
                        >
                          {e.chatId.isGroup
                            ? `New Message in ${e.chatId.chatName}`
                            : `New Message from ${getSender(
                                activeUser,
                                e.chatId.users
                              )}`}
                        </div>
                      );
                    })}
                    {notifications.length > 0 && (
                      <button
                        onClick={handleNotificationClick}
                        className="text-xs text-gray-700 cursor-pointer mt-3"
                      >
                        Clear All
                      </button>
                    )}
                  </div>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-full bg-white px-1 py-1 text-sm font-semibold text-gray-900  hover:bg-gray-200">
                        <HiOutlineDotsVertical className=" h-5 w-5 text-gray-600" />
                      </MenuButton>
                    </div>

                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <div className="py-1">
                        <MenuItem>
                          <div
                            className="block px-4 py-2 text-sm font-semibold text-gray-500 data-[focus]:bg-gray-100 data-[focus]:text-gray-500 cursor-pointer"
                            onClick={handleGroupOpen}
                          >
                            New Group
                          </div>
                        </MenuItem>
                        <MenuItem>
                          <div
                            className="block px-4 py-2 text-sm font-semibold text-gray-500 data-[focus]:bg-gray-100 data-[focus]:text-gray-500 cursor-pointer"
                            // onClick={() => setShowUpdatePass(true)}
                            onClick={handleOpen}
                          >
                            Update Password
                          </div>
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </Menu>
                </div>
              </div>
              <div>
                <div className="-mt-6 relative pt-6 px-4">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <input
                      onChange={handleSearch}
                      className="w-[99.5%] bg-[#f6f6f6] text-[#111b21] tracking-wider pl-9 py-[8px] rounded-[9px] outline-0"
                      type="text"
                      name="search"
                      placeholder="Search"
                    />
                  </form>
                  <div className="absolute top-[36px] left-[27px]">
                    <BsSearch style={{ color: "#c4c4c5" }} />
                  </div>
                  <Group />
                  <div
                    style={{ display: search ? "" : "none" }}
                    className="h-[100vh] absolute z-10 w-[100%] left-[0px] top-[70px] bg-[#fff] flex flex-col gap-y-3 pt-3 px-4"
                  >
                    <Search
                      searchResults={searchResults}
                      isLoading={isLoading}
                      handleClick={handleClick}
                      search={search}
                    />
                  </div>
                </div>

                <Contacts />
              </div>
            </div>
          ) : (
            <Profile className="min-w-[100%] sm:min-w-[360px] h-[100vh] bg-[#fafafa] shodow-xl relative" />
          )}
          <Chat className="chat-page relative lg:w-[100%] h-[100vh] bg-[#fafafa]" />
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h5 className="text-[20px] py-2 font-semibold tracking-wide text-center rounded-t-md text-[#fafafa] bg-[#166e48]">
            Password Update
          </h5>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col overflow-y-scroll scrollbar-hide h-[50vh] pb-1 w-full px-2">
              <div className="flex flex-col space-y-4 mt-4 px-3">
                <div className="input-area">
                  <label htmlFor="current-password" className="form-label">
                    Current Password
                    <span className="text-red-700 text-lg">*</span>
                  </label>
                  <input
                    id="current-password"
                    type="password"
                    className="form-control"
                    placeholder="Current Password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </div>
                <div className="input-area">
                  <label htmlFor="new-password" className="form-label">
                    New Password<span className="text-red-700 text-lg">*</span>
                  </label>
                  <input
                    id="new-password"
                    type="password"
                    className="form-control"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div className="input-area">
                  <label htmlFor="confirm-new-password" className="form-label">
                    Confirm New Password
                    <span className="text-red-700 text-lg">*</span>
                  </label>
                  <input
                    id="confirm-new-password"
                    type="password"
                    className="form-control"
                    placeholder="Confirm New Password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                </div>
                <div className="flex justify-end gap-x-3 mt-3">
                  <button
                    type="submit"
                    className="bg-[#880808] hover:bg-[#A52A2A] transition delay-150 px-4 py-1 text-[10.6px] tracking-wide text-[#fff]"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Box>
      </Modal>

      <Group open={showGroup} onClose={handleGroupClose} />
    </>
  );
}

export default Home;
